import * as React from "react";
import { graphql } from "gatsby";
import { Link } from "gatsby-plugin-react-i18next";
import Styled from "styled-components/macro";
import Media from "@uizard-io/styled-media-query";
import Meta from "../../../components/meta";
import Layout from "../../../components/layout/Layout";
import TemplatesSection from "../../../components/pages/templates/TemplatesSection";
import Content from "../../../components/layout/Content/Content";
import Shoutout from "../../../components/pages/signup/Shoutout";

import bankAppTabletImage from "../../../components/pages/home/HomeExamples/bank-tablet-app-cover.png";
import booktabImage from "../../../components/pages/home/HomeExamples/book-tab-app-cover.png";
import banktabwireframeImage from "../../../components/pages/home/HomeExamples/wireframe-online-banking-app-tablet-plp.png";
import cloudstorageImage from "../../../components/pages/home/HomeExamples/cloud-storage-tab-app-cover.png";
import cookingtabImage from "../../../components/pages/home/HomeExamples/cooking-tab-app-cover.png";
import crowdtabImage from "../../../components/pages/home/HomeExamples/crowdfunding-tablet-plp.png";
import fitnessTabletImage from "../../../components/pages/home/HomeExamples/fitness-tab-app-plp.png";
import learningtabImage from "../../../components/pages/home/HomeExamples/learning-tab-app-cover.png";
import moneytransfertabImage from "../../../components/pages/home/HomeExamples/money-transfer-tab-app-cover.png";
import musicstreamingtabImage from "../../../components/pages/home/HomeExamples/music-tab-app-cover.png";
import weatherdarktabImage from "../../../components/pages/home/HomeExamples/weather-tablet-dark-plp.png";
import weatherlighttabImage from "../../../components/pages/home/HomeExamples/weather-tablet-light-plp.png";
import newsfeedtabImage from "../../../components/pages/home/HomeExamples/news-tab-app-cover.png";
import moodboardtabImage from "../../../components/pages/home/HomeExamples/mood-board-app-tablet-plp.png";
import jobtabImage from "../../../components/pages/home/HomeExamples/job-finding-app-tablet-plp.png";
import uikittabImage from "../../../components/pages/home/HomeExamples/tablet-ui-kit-plp.png";
import producttabImage from "../../../components/pages/home/HomeExamples/productivity-tab-cover.png";
import notestabImage from "../../../components/pages/home/HomeExamples/notes-tab-app-cover.png";
import smarthometabImage from "../../../components/pages/home/HomeExamples/smart-home-tablet-plp.png";
import todotabImage from "../../../components/pages/home/HomeExamples/to-do-tab-app-cover.png";
import eCommerceTabletImage from "../../../components/pages/home/HomeExamples/e-commerce-tablet-app-cover.png";
import GMDTabletImage from "../../../components/pages/home/HomeExamples/GMD-tablet-app-cover.png";
import landoTabletImage from "../../../components/pages/home/HomeExamples/lando-tablet-cover.png";
import blogAppTabletImage from "../../../components/pages/home/HomeExamples/food-blog-tablet-plp.png";


const ContentExtended = Styled(Content)`
  margin-top: 10rem;

  ${Media.greaterThan("medium")`
    margin-top: 16rem;
  `}
`;

const BreadcrumbContainer = Styled.div`
  margin-top: 10rem;

  ${Media.greaterThan("medium")`
    margin-top: 16rem;
  `}

    margin-bottom: 1rem;
`;

const BreadcrumbLink = Styled(Link)`
  font-size: 1.5rem;

  &:hover {
    color: ${(props) => props.theme.colors.black};
    text-decoration: underline;
  }
`;

const Breadcrumb = Styled.span`
  font-size: 1.5rem;
`;

const Chevron = Styled.span`
  position: relative;
  margin: 0 0.5rem;
  top: 0.35rem;
`;

const Templates = ({ data }) => (
  <Layout>
    <Meta
      title="Tablet Design Templates | Templates For Tablet Apps | Uizard"
      description="Design a tablet app in minutes or adapt a desktop or mobile project to tablets easily. Uizard templates do the hard work so you don't have to."
      url="/templates/tablet-templates/"
    />

    <ContentExtended narrow>
      <BreadcrumbContainer>
        <BreadcrumbLink to="/templates/">Templates</BreadcrumbLink>
        <Chevron aria-hidden="true">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
          >
            <path d="M6.47 4.29l3.54 3.53c.1.1.1.26 0 .36L6.47 11.7a.75.75 0 1 0 1.06 1.06l3.54-3.53c.68-.69.68-1.8 0-2.48L7.53 3.23a.75.75 0 0 0-1.06 1.06z"></path>
          </svg>
        </Chevron>
        <Breadcrumb>Tablet Templates</Breadcrumb>
      </BreadcrumbContainer>
    </ContentExtended>

    <TemplatesSection
      title="Tablet design templates"
      description="Design your own tablet app with one of our premade tablet UI templates. Customization is easy with Uizard, meaning you can use our tablet templates as they are or adapt them to suit your own vision in a matter of minutes. Bring your tablet app vision to life today!"
      h1={true}
      thumbnails={[
        {
          image: bankAppTabletImage,
          alt: "banking tablet app design template",
          title: "Banking tablet app",
          link: "/templates/tablet-templates/online-banking-tablet-app/",
        },
        {
          image: banktabwireframeImage,
          alt: "banking tablet app wireframe",
          title: "Banking tablet app wireframe",
          link: "/templates/tablet-templates/banking-tablet-app-wireframe/",
        },
        {
          image: blogAppTabletImage,
          alt: "blog tablet app design template",
          title: "Blog tablet app",
          link: "/templates/tablet-templates/blog-tablet-app/",
        },
        {
          image: booktabImage,
          alt: "book reading tablet app design template",
          title: "Book reading tablet app",
          link: "/templates/tablet-templates/book-reading-tablet-app/",
        },
        {
          image: cloudstorageImage,
          alt: "cloud storage tablet app design template",
          title: "Cloud storage tablet app",
          link: "/templates/tablet-templates/cloud-storage-tablet-app/",
        },
        {
          image: cookingtabImage,
          alt: "cooking tablet app design template",
          title: "Cooking tablet app",
          link: "/templates/tablet-templates/cooking-tablet-app/",
        },
        {
          image: crowdtabImage,
          alt: "crowdfunding tablet app design template",
          title: "Crowdfunding tablet app",
          link: "/templates/tablet-templates/crowdfunding-tablet-app/",
        },
        {
          image: eCommerceTabletImage,
          alt: "e-shop app template for tablet template",
          title: "E-shop tablet app",
          link: "/templates/tablet-templates/e-commerce-tablet-app/",
        },
        {
          image: fitnessTabletImage,
          alt: "fitness tablet app design template",
          title: "Fitness tablet app",
          link: "/templates/tablet-templates/fitness-tablet-app/",
        },
        {
          image: GMDTabletImage,
          alt: "material design lite tablet app template",
          title: "Google Material Design tablet app",
          link: "/templates/tablet-templates/google-material-design-tablet-app/",
        },
        {
          image: jobtabImage,
          alt: "job tablet app template",
          title: "Job tablet app",
          link: "/templates/tablet-templates/job-tablet-app/",
        },
        {
          image: landoTabletImage,
          alt: "tablet app landing page design template",
          title: "Landing page for tablet",
          link: "/templates/tablet-templates/startup-web-page-tablet-format/",
        },
        {
          image: learningtabImage,
          alt: "learning tablet app design template",
          title: "Learning tablet app",
          link: "/templates/tablet-templates/learning-tablet-app/",
        },
        {
          image: moneytransfertabImage,
          alt: "money transfer tablet app design template",
          title: "Money transfer tablet app",
          link: "/templates/tablet-templates/money-transfer-tablet-app/",
        },
        {
          image: moodboardtabImage,
          alt: "mood board tablet app design template",
          title: "Mood board tablet app",
          link: "/templates/tablet-templates/mood-board-tablet-app/",
        },
        {
          image: musicstreamingtabImage,
          alt: "music streaming tablet app design template",
          title: "Music streaming tablet app",
          link: "/templates/tablet-templates/music-streaming-tablet-app/",
        },
        {
          image: newsfeedtabImage,
          alt: "news feed tablet app design template",
          title: "News feed tablet app",
          link: "/templates/tablet-templates/news-feed-tablet-app/",
        },
        {
          image: notestabImage,
          alt: "sticky notes tablet app design template",
          title: "Notes tablet app",
          link: "/templates/tablet-templates/notes-tablet-app/",
        },
        {
          image: producttabImage,
          alt: "productivity tablet app design template",
          title: "Productivity tablet app",
          link: "/templates/tablet-templates/productivity-tablet-app/",
        },
        {
          image: smarthometabImage,
          alt: "smart home tablet app design template",
          title: "Smart home tablet app",
          link: "/templates/tablet-templates/smart-home-tablet-app/",
        },
        {
          image: todotabImage,
          alt: "to do list tablet app design template",
          title: "To do list tablet app",
          link: "/templates/tablet-templates/to-do-list-tablet-app/",
        },
        {
          image: uikittabImage,
          alt: "universal tablet ui kit",
          title: "Universal tablet UI kit",
          link: "/templates/tablet-templates/universal-tablet-ui-kit/",
        },
        {
          image: weatherdarktabImage,
          alt: "weather tablet app design template dark mode",
          title: "Weather tablet app (dark)",
          link: "/templates/tablet-templates/weather-tablet-app-dark/",
        },
        {
          image: weatherlighttabImage,
          alt: "weather tablet app design template light mode",
          title: "Weather tablet app (light)",
          link: "/templates/tablet-templates/weather-tablet-app-light/",
        },
      ]}
    />

    <Content>
      <Shoutout
        headline="Design a tablet app in minutes"
        description="No tutorial needed!"
        cta="Sign up for free"
        imageA={data.shoutoutImageA.childImageSharp}
      />
    </Content>
  </Layout>
);

export default Templates;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    shoutoutImageA: file(
      relativePath: { eq: "shoutout/shoutout-image-a.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1232, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
